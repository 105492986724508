import React from "react";
import { Provider } from "react-redux";
import { configureStore } from "./store";

const store = configureStore();

export default ({ element }) => (
  <React.StrictMode>
    <Provider store={store}>{element}</Provider>
  </React.StrictMode>
);
