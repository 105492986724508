import createMemberReducer from "./createMemberReducer";
import { AYANA_SESSION } from "state/constants/actions";
import { loadState, saveState } from "state/utils/localStorage";
import crypto from "crypto";

export const getSessionId = () => {
  let sessionId = loadState(AYANA_SESSION);

  if (!sessionId) {
    crypto.randomBytes(16, (_, buf) => {
      let bufString = buf.toString("hex");
      saveState(AYANA_SESSION, bufString);
      sessionId = bufString;
    });
  }

  return sessionId;
};

export const sessionReducer = createMemberReducer(AYANA_SESSION, {
  sessionId: getSessionId(),
});
