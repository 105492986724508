import {
  BLANK,
  SUCCESS,
  FAILED,
  IN_PROGRESS,
  FETCH,
  FETCH_FAILED,
  FETCH_SUCCESS,
  UPDATE_FIELDS,
  RESET,
} from "../constants/actions";
import produce from "immer";
import { enableES5 } from "immer"

const initialState = {
  collection: [],
  uiState: BLANK,
  error: {},
};
enableES5();
const createReducer = (name) => {
  return produce((state, action) => {
    switch (action.type) {
      case `@${name}/${UPDATE_FIELDS}`:
        return {
          ...state,
          ...action.payload,
        };

      case `@${name}/${FETCH}`:
        state.uiState = IN_PROGRESS;
        return;

      case `@${name}/${FETCH_FAILED}`:
        state.uiState = FAILED;
        state.error = action.payload.error;
        return;

      case `@${name}/${FETCH_SUCCESS}`:
        state.uiState = SUCCESS;
        state.collection = action.payload?.collection || state.collection;
        return;

      case `@${name}/${RESET}`:
        return initialState;
      default:
        return state;
    }
  }, initialState);
};

export default createReducer;
