import {
  BLANK,
  SUCCESS,
  FAILED,
  IN_PROGRESS,
  FETCH,
  FETCH_FAILED,
  FETCH_SUCCESS,
  UPDATE_FIELDS,
  RESET,
  UPDATE_MEMBER,
} from "../constants/actions";
import produce from "immer";
import { enableES5 } from "immer"

const createMemberReducer = (name, initialStateArg = {}) => {
  const initialState = {
    member: initialStateArg,
    uiState: BLANK,
    error: {},
  };
  enableES5();
  return produce((state, action) => {
    switch (action.type) {
      case `@${name}/${UPDATE_FIELDS}`:
        return {
          ...state,
          ...action.payload,
        };

      case `@${name}/${FETCH}`:
        state.uiState = IN_PROGRESS;
        return;

      case `@${name}/${FETCH_FAILED}`:
        state.uiState = FAILED;
        state.error = action.payload.error;
        return;

      case `@${name}/${FETCH_SUCCESS}`:
        state.uiState = SUCCESS;
        state.member = action.payload?.member || state.member;
        return;

      case `@${name}/${UPDATE_MEMBER}`:
        return {
          ...state,
          member: {
            ...state.member,
            ...action.payload.member,
          },
        };

      case `@${name}/${RESET}`:
        return initialState;

      default:
        return state;
    }
  }, initialState);
};

export default createMemberReducer;
