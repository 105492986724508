// Async collection states
export const BLANK = "";
export const IN_PROGRESS = "IN_PROGRESS";
export const FAILED = "FAILED";
export const SUCCESS = "SUCCESS";

export const FETCH = "FETCH";
export const FETCH_FAILED = "FETCH_FAILED";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const UPDATE_FIELDS = "UPDATE_FIELDS";
export const RESET = "RESET";

// Async member state
export const FETCH_MEMBER = "FETCH_MEMBER";
export const UPDATE_MEMBER = "UPDATE_MEMBER";

// Example
export const EXAMPLE = "EXAMPLE";

// PROMOTIONS
export const PROMOTIONS = "PROMOTIONS";
export const PROMOTION_DETAIL = "PROMOTION_DETAIL";

// Session
export const AYANA_SESSION = "AYANA_SESSION";

// Lang Session
export const AYANA_LANG_SESSION = "AYANA_LANG_SESSION";

// MY_VOUCHER
export const MY_VOUCHER = "MY_VOUCHER";

// REWARDS
export const REWARDS = "REWARDS";
export const REWARDS_ITEM_LOCATION = "REWARDS_ITEM_LOCATION";
export const REWARDS_HISTORY = "REWARDS_HISTORY";
export const REWARDS_CATEGORY = "REWARDS_CATEGORY";
export const REWARD_DETAIL = "REWARD_DETAIL";

// MEMBER
export const MEMBER_POINT = "MEMBER_POINT";
export const DATA_MEMBER_POINT = "DATA_MEMBER_POINT";
export const SHOW_MEMBER_POINT_ERROR = "SHOW_MEMBER_POINT_ERROR";
export const MESSAGE_ERROR_DATA_MEMBER_POINT =
  "MESSAGE_ERROR_DATA_MEMBER_POINT";

// UNSUBSCRIBE CHECK KEY
export const UNSUBSCRIBE_CHECK_KEY = "UNSUBSCRIBE_CHECK_KEY";

// MY_RESERVATION
export const MY_RESERVATION = "MY_RESERVATION";

// GUEST INFO
export const GUEST_INFO = "GUEST_INFO";
