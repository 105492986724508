import createMemberReducer from "./createMemberReducer";
import { AYANA_LANG_SESSION } from "state/constants/actions";
import { loadState, saveState } from "state/utils/localStorage";

export const getLang = () => {
  let lang = loadState(AYANA_LANG_SESSION);

  if (!lang) {
    saveState(AYANA_LANG_SESSION, 'en');
    lang = 'en';
  }

  return lang;
};

export const langSessionReducer = createMemberReducer(AYANA_LANG_SESSION, {
  lang: getLang(),
});
