import { combineReducers } from "redux";

import createCollectionReducer from "./createCollectionReducer";
import createMemberReducer from "./createMemberReducer";
import {
  EXAMPLE,
  FETCH_MEMBER,
  PROMOTIONS,
  PROMOTION_DETAIL,
  REWARDS,
  REWARDS_HISTORY,
  MY_VOUCHER,
  REWARDS_CATEGORY,
  REWARDS_ITEM_LOCATION,
  REWARD_DETAIL,
  MEMBER_POINT,
  DATA_MEMBER_POINT,
  SHOW_MEMBER_POINT_ERROR,
  MESSAGE_ERROR_DATA_MEMBER_POINT,
  UNSUBSCRIBE_CHECK_KEY,
  MY_RESERVATION,
  GUEST_INFO,
} from "../constants/actions";
import { sessionReducer } from "./session";
import { langSessionReducer } from "./langSession";

const rootReducer = combineReducers({
  example: createCollectionReducer(EXAMPLE),
  promotions: createCollectionReducer(PROMOTIONS),
  rewards_history: createCollectionReducer(REWARDS_HISTORY),
  my_voucher: createCollectionReducer(MY_VOUCHER),
  promotion: createMemberReducer(PROMOTION_DETAIL),
  rewards: createCollectionReducer(REWARDS),
  rewards_category: createCollectionReducer(REWARDS_CATEGORY),
  rewards_item_location: createCollectionReducer(REWARDS_ITEM_LOCATION),
  my_reservation: createCollectionReducer(MY_RESERVATION),
  session: sessionReducer,
  lang: langSessionReducer,
  reward: createMemberReducer(REWARD_DETAIL),

  memberPoint: createMemberReducer(MEMBER_POINT),
  dataMemberPoint: createMemberReducer(DATA_MEMBER_POINT),
  showErrorMemberPoint: createMemberReducer(SHOW_MEMBER_POINT_ERROR),
  messageErrorMemberPoint: createMemberReducer(MESSAGE_ERROR_DATA_MEMBER_POINT),
  memberData: createMemberReducer(FETCH_MEMBER),
  checkUnsubscribeKey: createMemberReducer(UNSUBSCRIBE_CHECK_KEY),
  guestInformation: createMemberReducer(GUEST_INFO),
});

export default rootReducer;
